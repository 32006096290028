import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'Components/Tooltip'
import ProgressBar from 'Components/ProgressBar'

const CourseProgressBar = ({ progress, style, className }) => (
  <Tooltip tip={`${progress}% complete`} containerClass="block">
    <div>
      <ProgressBar progress={progress} className={className} style={style} />
    </div>
  </Tooltip>
)

CourseProgressBar.defaultProps = {
  progress: 0,
  className: '',
  style: {},
}

CourseProgressBar.propTypes = {
  progress: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default CourseProgressBar
