import React, { Component } from 'react'
import courseService from 'Services/courseService'
import PublicCourseCard from 'Components/PublicCourseCard'
import MyCourseCard from 'Components/MyCourseCard'
import PaginationBlock from 'Components/PaginationBlock'
import SchoolPinnedAnnouncement from 'Components/SchoolPinnedAnnouncement'
import Layout from 'Components/Layout'
import globalsService from 'Services/globalsService'
import InitialPropsHelper from 'Utils/InitialPropsHelper'
import schoolService from 'Services/schoolService'
import AlertBlock from 'Components/AlertBlock'
import GetGlobals from 'Utils/GetGlobals'
import PropTypes from 'prop-types'
import CourseCollectionDropdown from 'Components/CourseCollectionDropdown'
import Lazyload from 'Components/Lazyload'
import DashboardInfoBlock from 'Components/DashboardInfoBlock'

// IMPORTANT dont remove below comment will be used in mobile to insert mobile specific code
// eslint-disable-next-line
let DASHBOARD_MOBILE_ONLY_IMPORT

/*
disabling tour for now
const DashboardTourForAdmin = Lazyload(() =>
  import('Components/Tours/DashboardTourForAdmin')
)
*/

const BillingAlertForDashboard = Lazyload(() =>
  import('Components/BillingAlertForDashboard')
)

const courseCardContainerClasses = 'inline-block align-top mr-12 mb-12'
const courseSectionLabelClasses =
  'text-gray-800 my-8 mt-0 text-xl font-semibold tracking-wide'

class HomePage extends Component {
  static async getYlurnInitialProps() {
    const loggedUser = globalsService.getLoggedUser()
    return InitialPropsHelper({
      mySchoolCoursesData: loggedUser
        ? courseService.getMySchoolCourses()
        : null,
      schoolPublicCoursesData: courseService.getSchoolPublicCourses(),
      pinnedAnnouncement: schoolService.getPinnedAnnouncement(),
      courseCollections: courseService.getLandingPageCollections(),
      globals: GetGlobals(),
    })
  }

  constructor(props) {
    super(props)
    const emptyResp = { result: [] }
    this.state = {
      currentCollection: null,
      school: props.globals.school,
      mySchoolCoursesData: props.mySchoolCoursesData || emptyResp, // to avoid null checking
      schoolPublicCoursesData: props.schoolPublicCoursesData || emptyResp,
      collectionCoursesData: null,
      pinnedAnnouncement: props.pinnedAnnouncement,
      loggedUser: globalsService.getLoggedUser(),
    }
    this.getMySchoolCourses = this.getMySchoolCourses.bind(this)
    this.getSchoolPublicCourses = this.getSchoolPublicCourses.bind(this)
    this.getPinnedAnnouncement = this.getPinnedAnnouncement.bind(this)
    this.handleCollectionChange = this.handleCollectionChange.bind(this)
    this.getCollectionCatalog = this.getCollectionCatalog.bind(this)
  }

  componentDidMount() {
    const { school } = this.state
    if (school) this.getCourseCatalog()

    // IMPORTANT dont remove below comment will be used in mobile to insert mobile specific code
    // eslint-disable-next-line
    let DASHBOARD_MOBILE_ONLY_MOUNT_CODE
  }

  getCourseCatalog() {
    const { currentCollection } = this.state
    if (currentCollection == null) {
      if (globalsService.getLoggedUser()) this.getMySchoolCourses(null, true)
      this.getSchoolPublicCourses(null, true)
      this.getPinnedAnnouncement(true)
    } else {
      this.getCollectionCatalog()
    }
  }

  getCollectionCatalog() {
    const { currentCollection } = this.state
    this.setState({ collectionCoursesData: null })
    courseService.getCollectionCoursesForLanding(currentCollection).then(
      (data) => {
        this.setState({ collectionCoursesData: data })
      },
      () => {}
    )
  }

  getPinnedAnnouncement() {
    schoolService.getPinnedAnnouncement(true).then(
      (pa) => {
        this.setState({ pinnedAnnouncement: pa })
      },
      () => {}
    )
  }

  getMySchoolCourses(page, invalidateCache) {
    courseService.getMySchoolCourses(page, invalidateCache).then(
      (data) => {
        this.setState({ mySchoolCoursesData: data })
      },
      () => {}
    )
  }

  getSchoolPublicCourses(page, invalidateCache) {
    courseService.getSchoolPublicCourses(page, invalidateCache).then(
      (data) => {
        this.setState({ schoolPublicCoursesData: data })
      },
      () => {}
    )
  }

  handleCollectionChange(newCollectionId) {
    const { currentCollection } = this.state
    if (currentCollection === newCollectionId) return
    this.setState({ currentCollection: newCollectionId }, this.getCourseCatalog)
  }

  render() {
    const { courseCollections } = this.props
    const {
      mySchoolCoursesData,
      schoolPublicCoursesData,
      collectionCoursesData,
      pinnedAnnouncement,
      school,
      loggedUser,
      currentCollection,
    } = this.state

    const isSchoolAdmin =
      loggedUser && loggedUser.schoolRoles && loggedUser.schoolRoles.isAdmin

    return (
      <div className="flex px-4 md:px-8 pb-40 w-full">
        <div className="w-full">
          <h5 className="text-gray-700 text-2xl my-6 tracking-widest font-semibold">
            {school.name}
          </h5>
          {isSchoolAdmin && <BillingAlertForDashboard />}
          {/* disabling tour for now
          isSchoolAdmin && <DashboardTourForAdmin /> 
          */}
          {isSchoolAdmin &&
          mySchoolCoursesData.result.length === 0 &&
          schoolPublicCoursesData.result.length === 0 ? (
            <DashboardInfoBlock />
          ) : (
            <div>
              <SchoolPinnedAnnouncement data={pinnedAnnouncement} />
              <div className="pb-8">
                <CourseCollectionDropdown
                  collections={courseCollections}
                  onChange={this.handleCollectionChange}
                />
              </div>
            </div>
          )}
          {/* code above this is common while browsing collections */}
          {currentCollection == null ? (
            <div>
              <div>
                {mySchoolCoursesData.result.length > 0 && (
                  <div>
                    <h6 className={courseSectionLabelClasses}>My Courses</h6>
                    <div>
                      {mySchoolCoursesData.result.map((c) => (
                        <div
                          className={`${courseCardContainerClasses} ylurnMyCourse`}
                          key={c.id}
                        >
                          <MyCourseCard course={c} />
                        </div>
                      ))}
                      <PaginationBlock
                        data={mySchoolCoursesData}
                        callback={this.getMySchoolCourses}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                {schoolPublicCoursesData.result.length > 0 && (
                  <div>
                    <h6 className={courseSectionLabelClasses}>All Courses</h6>
                    <div>
                      {schoolPublicCoursesData.result.map((c) => (
                        <div
                          className={`${courseCardContainerClasses} ylurnPublicCourse`}
                          key={c.id}
                        >
                          <PublicCourseCard course={c} />
                        </div>
                      ))}
                      <PaginationBlock
                        data={schoolPublicCoursesData}
                        callback={this.getSchoolPublicCourses}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                {schoolPublicCoursesData.result.length === 0 &&
                  mySchoolCoursesData.result.length === 0 &&
                  !isSchoolAdmin && (
                    <AlertBlock variant="info">
                      {loggedUser
                        ? 'No courses found.'
                        : 'No courses found. Login to see all your courses.'}
                    </AlertBlock>
                  )}
              </div>
            </div>
          ) : (
            <div>
              {collectionCoursesData && (
                <div>
                  {collectionCoursesData.result.length === 0 && (
                    <AlertBlock variant="warning" className="mr-4 mb-4">
                      No courses found
                    </AlertBlock>
                  )}
                  {collectionCoursesData.result.length > 0 && (
                    <div className="mt-2">
                      {collectionCoursesData.result.map((collectionCourse) => (
                        <div
                          className={`${courseCardContainerClasses} ylurnPublicCourse`}
                          key={collectionCourse.id}
                        >
                          <PublicCourseCard course={collectionCourse.course} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <br />
        <br />
      </div>
    )
  }
}

HomePage.defaultProps = {
  mySchoolCoursesData: null,
  schoolPublicCoursesData: null,
  pinnedAnnouncement: null,
  courseCollections: [],
}

HomePage.propTypes = {
  mySchoolCoursesData: PropTypes.object,
  schoolPublicCoursesData: PropTypes.object,
  pinnedAnnouncement: PropTypes.object,
  courseCollections: PropTypes.array,
  globals: PropTypes.object.isRequired,
}

export default Layout(HomePage, {
  sidebarVariant: 'HOME',
  isFooterPresent: true,
  fullWidth: true,
})
