import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const CourseCollectionsDropdown = ({ onChange, collections }) => {
  const [selectedCourse, setSelectedCourse] = useState(-1)

  const handleChange = (event) => {
    setSelectedCourse(event.target.value)
    // select-element cant match with "null"
    if (event.target.value !== -1) onChange(event.target.value)
    else onChange(null)
  }
  return (
    <Select
      style={{ borderRadius: '16px', minWidth: '200px' }}
      classes={{
        root:
          'opacity-100 cursor-pointer rounded-xl bg-white border border-solid border-gray-300 hover:border-gray-500 tracking-widest font-bold py-5 px-8 focus:border-gray-500',
        icon: 'right-4',
        selectMenu: 'rounded-xl',
      }}
      variant="filled"
      value={selectedCourse}
      onChange={handleChange}
      disableUnderline
    >
      <MenuItem value={-1}>All Courses</MenuItem>
      {collections.map((collection) => (
        <MenuItem value={collection.id} key={collection.id}>
          {collection.name}
        </MenuItem>
      ))}
    </Select>
  )
}

CourseCollectionsDropdown.propTypes = {
  collections: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CourseCollectionsDropdown
