import React from 'react'
import ImageBlock from 'Components/ImageBlock'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Badge from '@material-ui/core/Badge'
import CourseProgressBar from 'Components/CourseProgressBar'
import CourseCardButton from 'Components/CourseCardButton'

const thumbnailHeight = 300
const thumbnailWidth = 300

const MyCourseCard = ({ course }) => {
  const urlHref = {
    pathname: '/course/[slug]/content',
    query: { slug: course.slug },
  }
  const urlAs = `/course/${course.slug}/content`
  return (
    <div className="inline-block">
      {course != null && (
        <Link href={urlHref} as={urlAs}>
          <>
            <div
              style={{
                width: `${thumbnailWidth}px`,
              }}
              className="ylurnCourseCard relative cursor-pointer border border-gray-300 hover:shadow-lg rounded-xl"
            >
              <Link href={urlHref} as={urlAs}>
                <a>
                  <div
                    style={{
                      height: `${thumbnailHeight}px`,
                      background:
                        'linear-gradient(180deg, rgba(16, 20, 45, 0) 59.38%, rgba(16, 20, 45, 0.2) 74.48%, rgba(16, 20, 45, 0.6) 100%)',
                    }}
                    className="rounded-lg relative"
                  >
                    <ImageBlock
                      width={thumbnailWidth}
                      height={thumbnailHeight}
                      alt={course.title}
                      className="shadow-inner w-full object-cover"
                      style={{
                        height: `${thumbnailHeight}px`,
                        mixBlendMode: 'multiply',
                        borderRadius: 'inherit',
                      }}
                      src={course.thumbnail}
                    />
                    {course.badge > 0 && (
                      <Badge
                        color="error"
                        classes={{
                          root: 'block absolute top-4 right-4',
                        }}
                        badgeContent={course.badge}
                      />
                    )}
                    <div className="mb-5 ml-4 mr-4 absolute bottom-0">
                      <p
                        className="text-white text-xl leading-6 font-bold overflow-hidden"
                        style={{
                          // select tailwind leading property properly
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          /* to specify the number of lines you want the text to run through... */
                          WebkitLineClamp: 2,
                          /* hide the overflowing text, i.e, texts that did not fit in to the box */
                        }}
                      >
                        {course.title}
                      </p>
                    </div>
                  </div>
                </a>
              </Link>
            </div>
            <div className="relative">
              {course && course.roles && course.roles.isStudent && (
                <div className="mt-3 w-full">
                  <CourseProgressBar
                    progress={course.progress}
                    className="cursor-pointer rounded-lg"
                  />
                </div>
              )}
              <div className="w-full mt-2">
                <CourseCardButton course={course} myCourse />
              </div>
            </div>
          </>
        </Link>
      )}
    </div>
  )
}

MyCourseCard.propTypes = {
  course: PropTypes.object.isRequired,
}

export default MyCourseCard
