import React from 'react'
import Linkify from 'react-linkify'
import PropTypes from 'prop-types'
import AlertBlock from 'Components/AlertBlock'

const SchoolPinnedAnnouncement = ({ data }) => (
  <div>
    {data && data.body && (
      <div className="mt-4 mr-4 mb-6 ml-0">
        <AlertBlock showIcon variant="info" style={{ padding: '16px' }}>
          <Linkify properties={{ target: '_blank' }}>
            <span>{data.body}</span>
          </Linkify>
        </AlertBlock>
      </div>
    )}
  </div>
)

SchoolPinnedAnnouncement.defaultProps = {
  data: null,
}

SchoolPinnedAnnouncement.propTypes = {
  data: PropTypes.object,
}

export default SchoolPinnedAnnouncement
