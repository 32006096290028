import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'

const classes = {
  root: 'h-2 rounded-lg',
  colorPrimary: 'bg-green-300',
  bar: 'rounded-lg bg-green-600',
}

const ProgressBar = ({
  variant,
  style,
  className,
  progress, // number from 0 - 100
}) => (
  <div>
    {progress !== null && (
      <LinearProgress
        style={style}
        classes={classes}
        className={className}
        color={variant}
        variant="determinate"
        value={progress}
      />
    )}
  </div>
)

ProgressBar.defaultProps = {
  variant: 'primary',
  className: '',
  style: {},
  progress: null,
}

ProgressBar.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  progress: PropTypes.any,
}

export default ProgressBar
