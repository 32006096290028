import React, { useContext } from 'react'
import Link from 'next/link'
import {
  CurrencyRupeeIcon,
  LockClosedIcon,
  GlobeIcon,
} from '@heroicons/react/solid'
import FlexGroup from 'Components/FlexGroup'
import GetSchoolUrl from 'Utils/GetSchoolUrl'
import Button from 'Components/Button'
import GlobalsContext from 'Contexts/GlobalsContext'
import PropTypes from 'prop-types'

const CourseTypeOption = ({ label, Icon, tintColor, bgColor }) => (
  <li>
    <FlexGroup
      style={{ marginBottom: '8px' }}
      left={
        <div
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            marginRight: '8px',
          }}
          className={`flex items-center justify-center ${bgColor}`}
        >
          <Icon className={`h-5 ${tintColor}`} />
        </div>
      }
      middle={<p className="text-gray-700 text-xl">{label}</p>}
      right={<div />}
    />
  </li>
)

CourseTypeOption.propTypes = {
  Icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  tintColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
}

const DashboardInfoBlock = () => {
  const { school } = useContext(GlobalsContext)
  return (
    <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
      <h5 className="text-blue-800 text-3xl mb-3">Welcome!</h5>
      <p className="text-gray-700 text-xl">
        Thank you for signing up. Your account was successfully created. The
        dashboard URL for your account is -&nbsp;
        <Link href="/">
          <a>{GetSchoolUrl(school)}</a>
        </Link>
        . You can bookmark it in your browser for quick access.
      </p>
      <br />
      <hr />
      <h5 className="text-3xl text-blue-800 mb-2">Create your first course!</h5>
      <div>
        <p className="text-gray-700 text-xl mb-1">
          You can create 3 types of courses on our platform.
        </p>
        <ul
          style={{ listStyle: 'none', paddingLeft: '0', marginBottom: '20px' }}
        >
          <CourseTypeOption
            label="Paid - Students pay course fees to join"
            Icon={CurrencyRupeeIcon}
            tintColor="text-green-500"
            bgColor="bg-green-200"
          />
          <CourseTypeOption
            label="Public - Students can join for free"
            Icon={GlobeIcon}
            tintColor="text-blue-500"
            bgColor="bg-blue-200"
          />
          <CourseTypeOption
            label="Private - Only students you invite can join"
            Icon={LockClosedIcon}
            tintColor="text-yellow-600"
            bgColor="bg-yellow-300"
          />
        </ul>
        <Link href="/admin/courses/create">
          <Button
            color="primary"
            variant="contained"
            style={{ marginBottom: '8px' }}
          >
            CREATE A COURSE
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default DashboardInfoBlock
